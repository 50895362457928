import * as React from "react"
import Layout from "../components/layout"
import GalleryLight from "../components/GalleryLight"

const GalleryPageLight = () => (
  <Layout>
    <GalleryLight />
  </Layout>
)

export default GalleryPageLight